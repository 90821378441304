<script lang="ts" setup>
const props = defineProps({
  no: String
})
</script>

<template>
  <!-- <div class="number">{{ props.no }}</div> -->
  <div class="icon">
    <svg-icon :name="`icon-section-no${props.no}`"></svg-icon>
  </div>
</template>

<style lang="scss" scoped>
.icon {
  color: $color-gray-3;
  transition: color 0.5s ease-in-out;

  &.white {
    color: $color-white-2;
  }

  &.brown {
    color: $color-brand-4_1;
  }
}

// .number {
//   position: relative;
//   padding-right: toRem(30);
//   font-size: toRem(158);
//   line-height: 1;
//   color: $color-gray-3;
//   &::after {
//     content: '';
//     position: absolute;
//     right: 0;
//     bottom: toRem(8);
//     width: toRem(22);
//     height: toRem(22);
//     background-color: $color-gray-3;
//   }
// }</style>
