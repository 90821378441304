<script lang="ts" setup></script>

<template>
  <div class="title">
    <div class="title__logo" v-if="$slots.logo">
      <slot name="logo" />
    </div>
    <div class="title__en" v-if="$slots.en">
      <slot name="en" />
    </div>
    <div class="title__tw">
      <slot name="tw" />
    </div>
    <slot name="link" />
  </div>
</template>

<style lang="scss" scoped>
.title {
  // &__logo {}

  &__en {
    font-size: toRem(80);
    // font-size: 5.56vw;
    margin-top: toRem(15);
    margin-bottom: toRem(15);

    @include max-media(1280) {
      font-size: toRem(68);
    }

    @include max-media(990) {
      font-size: toRem(60);
    }

    @include max-media(420) {
      font-size: toRem(50);
    }

    @include max-media(340) {
      font-size: toRem(42);
    }
  }

  &__tw {
    margin-bottom: toRem(25);
    font-weight: 500;
    // font-size: 3.33vw;
    font-size: toRem(48);
    line-height: 1.4;

    @include max-media(1280) {
      font-size: toRem(36);
    }

    @include max-media(990) {
      font-size: toRem(32);
    }

    @include max-media(420) {
      font-size: toRem(28);
    }

    @include max-media(340) {
      font-size: toRem(24);
    }
  }
}
</style>
